import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import geosafeLogo from "../assets/images/geosafe-logo.png";
import geosafeLogoWhite from "../assets/images/geosafe-logo-white.png";
import geoLoginImage from "../assets/images/geo-logo-login.png";
import Loader from "../components/loader/Loader";
import { AppContext } from "../context/AppContext";
import TextFieldOutlinedEmailInput from "../components/shared/TextFieldOutlinedEmailInput/TextFieldOutlinedEmailInput";
import TextFieldPassword from "../components/shared/TextFieldPassword/TextFieldPassword";
import { loginUser } from "../services/AuthService";
import { saveUserInfo, saveUserToken } from "../utils/AuthUtils";
import "../components/login/Login.scss";
import { ToastContainer, toast } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  showServerExceptionOnToast,
} from "../utils/ExceptionUtils";
import { useTheme } from "@emotion/react";

const defaultTheme = createTheme();

export default function LoginPage() {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [emailAddress, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isFilledPassword, setIsFilledPassword] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const [showLoader, setShowLoader] = useState(false);
  const theme = useTheme();
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const payload = {
        username: emailAddress,
        password: password,
      };

      setShowLoader(true);
      loginUser(payload)
        .then((response) => {
          if (response.Status === 0) {
            appContext.setUserDetails(response);
            appContext.setSelectedUserApp({
              id: response.Data.UserApps[0].AppId,
              label: response.Data.UserApps[0].AppName,
              ...response.Data.UserApps[0],
            });
            saveUserInfo(response.Data);
            saveUserToken(response.JWTid);
            navigate(from, { replace: true });
          } else {
            showServerExceptionOnToast(toast, 1, response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onEnterPassword = (value) => {
    if (value.length === 0) {
      setIsFilledPassword(false);
    } else {
      setIsFilledPassword(true);
    }
    setPassword(value);
  };

  const onEnterEmail = (value, valid) => {
    if (valid) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
    setEmail(value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundImage: `url(${geoLoginImage})`,
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[100]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          elevation={0}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff'
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <img
                src={theme.palette.mode === 'dark' ? geosafeLogoWhite : geosafeLogo}
                alt="Geosafe logo"
                className="geosafe-logo-image"
              />
            </div>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextFieldOutlinedEmailInput
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="off"
                placeHolderText={"Email address"}
                onTextChange={onEnterEmail}
                sx={{
                  mt: 7,
                  mb: 2,
                  width: "12.5rem",
                  height: "2.5rem",
                  borderRadius: 10,
                  boxShadow: 0,
                }}
              />

              <TextFieldPassword
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
                placeHolderText={"Password"}
                onTextChange={onEnterPassword}
                sx={{
                  "& .MuiFilledInput-root": {
                    background: "rgb(232, 241, 250)",
                  },
                }}
              />

              <Button
                disabled={!isFilledPassword || !isValidEmail}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 7,
                  mb: 2,
                  width: "12.5rem",
                  height: "2.5rem",
                  borderRadius: 10,
                  boxShadow: 0,
                  bgcolor: "#1e86f0",
                  "&:hover": {
                    boxShadow: 0,
                    bgcolor: "#4da6ff",
                  },
                  "&:disabled": {
                    backgroundColor: "#cde1f4",
                    color: "#ffffff",
                  },
                }}
              >
                login
              </Button>

              <Grid
                container
                sx={{
                  marginTop: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                <Grid item xs>
                  <Link
                    href="/forgotten-password"
                    variant="body2"
                    sx={{
                      color: theme.palette.text.primary,
                      textDecorationColor: "#000000",
                      "&:hover": { color: "#555555" },
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              {showLoader && <Loader />}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer position="top-center" autoClose={3000} />
    </ThemeProvider>
  );
}
