import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Supercluster from "supercluster";
import { getMapConfig } from "../../../../config/MapConfig";
import { DEFAULT_ZOOM_LEVEL } from "../../../../constants/Constants";
import { AppContext } from "../../../../context/AppContext";
import {
  getColorCodeByReasonCode,
} from "../../../../utils/CommonUtils";
import FeatureMarker from "./FeatureMarker";
import ACTTransaction from "../../../shared/ACTTransaction";
import ACTPopover from "../../../shared/ACTPopover";

const DashboardSuperClusterMap = (props) => {
  const appContext = useContext(AppContext);
  const { data, selectedApp, dateFilter, selectedReason } = props;
  const { MinLongitude, MinLatitude, MaxLongitude, MaxLatitude } =
    selectedApp.ViewPort;
  // const position = { lat: MaxLatitude, lng: MaxLongitude };
  const center = {
    lat: (MinLatitude + MaxLatitude) / 2,
    lng: (MinLongitude + MaxLongitude) / 2,
  };
  const { APIKey, MapId } = getMapConfig();

  return (
    <APIProvider apiKey={APIKey}>
      <div style={{ height: "60vh", width: "100%" }}>
        <Map
          defaultZoom={DEFAULT_ZOOM_LEVEL}
          defaultCenter={center}
          mapId={MapId}
          gestureHandling={"auto"}
          disableDefaultUI={true}
          zoomControl={true}
          streetViewControl={true}
          fullscreenControl={true}
        >
          {data && (
            <Markers
              makerData={data}
              selectedApp={selectedApp}
              dateFilter={dateFilter}
              selectedReason={selectedReason}
              reasonsForFail={appContext.failedReasons}
            />
          )}
        </Map>
      </div>
    </APIProvider>
  );
};

const Markers = ({ makerData, reasonsForFail }) => {
  const map = useMap();
  const [clusters, setClusters] = useState([]);
  const [points, setPoints] = useState(makerData.Data.Results);
  const [selectedPin, setSelectedPin] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreItems, setMoreItems] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // {
  //   extent: 256,
  //   radius: 80,
  //   maxZoom: 16,
  // }
  const supercluster = useRef(
    new Supercluster({
      radius: 80,
      maxZoom: 16,
    })
  );

  useEffect(() => {
    setPoints(makerData.Data.Results);
  }, [makerData]);

  const updateClusters = useCallback(() => {
    if (!map) return;

    const bounds = map.getBounds();
    const zoom = map.getZoom();

    if (bounds) {
      const clusters = supercluster.current.getClusters(
        [
          bounds.getSouthWest().lng(),
          bounds.getSouthWest().lat(),
          bounds.getNorthEast().lng(),
          bounds.getNorthEast().lat(),
        ],
        zoom
      );
      setClusters(clusters);
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const listener = map.addListener("bounds_changed", updateClusters);
    return () => {
      listener.remove();
    };
  }, [map, updateClusters]);

  useEffect(() => {
    if (!points) return;
    supercluster.current.load(
      points.map((point) => {
        return {
          type: "Feature",
          properties: { cluster: false, ...point },
          geometry: {
            type: "Point",
            coordinates: [point.Longitude, point.Latitude],
          },
        };
      })
    );
    updateClusters();
  }, [points]);

  const getClusterColor = (pointCount, zoom) => {
    const highDensityThreshold = 100;
    const lowDensityThreshold = 10;

    if (pointCount >= highDensityThreshold) {
      const svg =
        '<svg fill="#e65100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    } else if (pointCount >= lowDensityThreshold) {
      const svg =
        '<svg fill="#f57c00" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    } else {
      const svg =
        '<svg fill="#ffa726" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    }
  };

  const onMarkerClick = (marker, item) => {
    setSelectedPin({ marker, item });
  };

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPin(null);
  }, []);

  const onCopyData = (text) => {
    navigator.clipboard.writeText(text);
  };

  const onViewSimilarLocations = (e, items) => {
    setAnchorEl(e.currentTarget);
    setMoreItems(items);
  };

  if (!points) {
    return <></>;
  }

  return (
    <>
      {clusters.map((cluster) => {
        const [lng, lat] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        if (isCluster) {
          const color = getClusterColor(pointCount, map.getZoom());

          return (
            <AdvancedMarker
              key={`cluster-${cluster.id}`}
              position={{ lat, lng }}
              label={`${pointCount}`}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.current.getClusterExpansionZoom(cluster.id),
                  20
                );
                map.setZoom(expansionZoom);
                map.panTo({ lat, lng });
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "3rem",
                  height: "3rem",
                  backgroundImage: color,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <span style={{ fontSize: ".60rem", color: "white" }}>
                  {pointCount}
                </span>
              </div>
            </AdvancedMarker>
          );
        }
        const { properties } = cluster;
        return (
          <FeatureMarker
            key={properties.GeoReferenceId}
            position={{ lat, lng }}
            onMarkerClick={onMarkerClick}
            item={properties}
            bgColor={getColorCodeByReasonCode(
              properties.ReasonForFailure,
              reasonsForFail
            )}
          />
        );
      })}

      {selectedPin && (
        <InfoWindow
          anchor={selectedPin.marker}
          onCloseClick={handleInfoWindowClose}
        >
          {/* {selectedPin?.item && getTransactionById(selectedPin?.item)} */}
          {selectedPin?.item && (
            <ACTTransaction
              selectedGeoRefId={selectedPin?.item.GeoReferenceId}
              points={points}
              failedReasons={reasonsForFail}
              onCopyData={onCopyData}
              onViewSimilarLocations={onViewSimilarLocations}
            />
          )}

          {moreItems && (
            <ACTPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              moreItems={moreItems}
              onCopyData={onCopyData}
              failedReasons={reasonsForFail}
            />
          )}
        </InfoWindow>
      )}
    </>
  );
};

export default DashboardSuperClusterMap;
