import React, { useContext } from "react";
import dayjs from "dayjs";
import { IconButton, Typography } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { getCurrentTimeInHHMM, getDateFromString } from "../../utils/DateUtils";
import ACTDropdownList from "../shared/ACTDropdownList";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import { AppContext } from "../../context/AppContext";
import { useTheme } from "@mui/material/styles";

const DashboardToolbarDesktop = (props) => {
  const { reasonsForFail, onRefreshData, type, userApps } = props;

  const { dashboardStates, setDashboardData } = useContext(AppContext);
  const theme = useTheme();
  const refreshData = (newValue, type, key) => {
    if (onRefreshData) {
      onRefreshData(newValue, type);
      if (type === 3) {
        setDashboardData("date", {
          startDate: newValue,
          endDate: newValue,
        });
      } else {
        setDashboardData(key, newValue);
      }
    }
  };
  const onRefreshClick = () => {
    onRefreshData({}, 6);
  };

  const getSelectedDate = () => {
    const date = dashboardStates["date"];
    if (date) {
      return getDateFromString(date.startDate);
    } else {
      return dayjs();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "10px",
        alignItems: "center",
        color: theme.palette.text.primary,
      }}
    >
      <DashboardDatepicker
        onRefreshData={refreshData}
        type={type}
        defaultDate={getSelectedDate()}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff',
          color: theme.palette.text.primary,
        }}
      >
        {reasonsForFail && (
          <ACTDropdownList
            name={"failed_reasons"}
            options={reasonsForFail}
            title={"Select Reason for Fail"}
            type={5}
            onRefreshData={refreshData}
            selectedItem={dashboardStates["failed_reasons"] || null}
          />
        )}
        {userApps && (
          <ACTDropdownList
            name={"user_apps"}
            options={userApps}
            title={"Select User Apps"}
            type={2}
            onRefreshData={refreshData}
            selectedItem={dashboardStates["user_apps"] || null}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Typography noWrap style={{
          color: theme.palette.text.primary,
        }}>Last Updated: {getCurrentTimeInHHMM()} </Typography>
        <IconButton onClick={onRefreshClick}>
          <SyncIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default DashboardToolbarDesktop;
