export const getReasonsNameByCode = (code, failedReasons) => {
  if (!failedReasons) return null;
  const itemFound = failedReasons.find(
    (reasonItem) => reasonItem.Code === code
  );
  return itemFound ? itemFound.FriendlyName : "";
};

export const getColorCodeByReasonCode = (reasonCode, failedReasons) => {
  if (!failedReasons) return null;

  const itemFound = failedReasons.find(
    (reasonItem) => reasonItem.Code === reasonCode
  );
  return itemFound ? itemFound.Color : "#9dc5ed";
};

export const getLocatedCodeByName = (name) => {
  if (name === "GPS") return 0;
  else if (name === "WIFI") return 1;
  else if (name === "Cellular") return 2;
  else if (name === "IpAddress") return 3;
  return name;
};

export const getLocatedNameByCode = (code) => {
  // 0: GPS, 1: Wi-Fi, 2: Cell, 3: IP Address
  if (code === "0" || code === 0) return "GPS";
  else if (code === "1" || code === 1) return "Wi-Fi";
  else if (code === "2" || code === 2) return "Cell";
  else if (code === "3" || code === 3) return "IP Address";
  return code;
};

export const getReasonForLookupNameByCode = (code) => {
  // 0 - Login, 1 - Validate Bet, 2 - Place Bet, 3 - Purchase
  if (code === "0" || code === 0) return "Login";
  else if (code === "1" || code === 1) return "Validate Bet";
  else if (code === "2" || code === 2) return "Place Bet";
  else if (code === "3" || code === 3) return "Purchase";
  return code;
};

export const getReasonForLookupColorByCode = (code) => {
  // Login - #006064
  // Validate - #536DFE
  // Place bet - #FF1744
  // Purchase - #FB8C00
  if (code === "0" || code === 0) return "#006064";
  else if (code === "1" || code === 1) return "#536DFE";
  else if (code === "2" || code === 2) return "#FF1744";
  else if (code === "3" || code === 3) return "#FB8C00";
  return code;
};

export const exportToCsv = (filename, rows) => {
  const processRow = (row) => {
    return Object.values(row)
      .map((value) => `"${value}"`)
      .join(",");
  };

  const csvContent = [
    Object.keys(rows[0]).join(","), // header row first
    ...rows.map(processRow),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getReasonForFailWithShowAll = (failedReasons) => {
  if (!failedReasons) return null;

  const results = failedReasons.map((item) => {
    return {
      ...item,
      label: item.FriendlyName,
      id: item.Code,
      key: item.Name,
      color: item.Color,
    };
  });
  return results;
};

export const getReasonForFailWithAllOption = (failedReasons) => {
  if (!failedReasons) return null;

  const results = failedReasons
    .filter((item) => item.Code !== -2)
    .map((item) => {
      return {
        label: item.FriendlyName,
        id: item.Code,
        key: item.Name,
        color: item.Color,
        selected: false,
      };
    });
  const showAll = {
    label: "All",
    key: "all",
    id: -2,
    selected: false,
  };
  const finalResult = [...results, { ...showAll }];
  return finalResult;
};

export const getValidationStatus = (param) => {
  return param === 0 ? "Failed" : param === 1 ? "Success" : "Undetermined";
};

export const getTypeAndIdFromPathComponent = (url) => {
  const pathComponents = url.split("/");

  if (pathComponents.length > 2) {
    // Get the last two components
    const lastComponent = pathComponents[pathComponents.length - 1];
    const secondLastComponent = pathComponents[pathComponents.length - 2];

    return { type: secondLastComponent, id: lastComponent };
  } else {
    return { type: null, id: null };
  }
};
