import React, { useContext } from "react";
import Dashboard from "../components/home/Dashboard";
import { AppContext } from "../context/AppContext";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import { Main } from "../components/shared/Main";

const Home = () => {
  const appContext = useContext(AppContext);
  return (
    <Main
      open={appContext.drawerOpen}
      sx={{
        overflowX: "hidden",
        height: '100vh' 
      }}
    >
      <DrawerHeader />
      <Dashboard />
    </Main>
    
  );
};

export default Home;
