import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import {
  getCurrentDateYYYYMMDD,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import dayjs from "dayjs";
import ACTDropdownList from "../shared/ACTDropdownList";
import { AppContext } from "../../context/AppContext";
import { getReasonForFailWithShowAll } from "../../utils/CommonUtils";
import { useTheme } from "@emotion/react";

const LocationToolbar = (props) => {
  const {
    summaryType,
    summaryId,
    exportHandler,
    printHandler,
    refreshDataHandler,
  } = props;
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const onDateChange = (value, type) => {
    if (value && refreshDataHandler) {
      refreshDataHandler(value, 1);
    }
  };

  const refreshData = (data) => {
    if (refreshDataHandler) {
      refreshDataHandler(data, 2);
    }
  };


  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexGrow: 1,
        }}
      >
        <DashboardDatepicker
          onRefreshData={onDateChange}
          type={2}
          defaultDate={dayjs()}
        />
        {appContext.failedReasons && (
          <ACTDropdownList
            options={getReasonForFailWithShowAll(appContext.failedReasons)}
            title={"Select Reason for Fail"}
            type={5}
            onRefreshData={refreshData}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          marginRight: "4rem",
        }}
      >
        {summaryType === "device" && (
          <div
            style={{
              display: "flex",
              fontSize: "1rem",
              fontWeight: "500",
              flexDirection: "row",
              marginRight: "1rem",
            }}
          >
            {"Device:"}
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                marginLeft: "0.25rem",
              }}
            >
              {summaryId}
            </div>
          </div>
        )}
        {summaryType === "account" && (
          <div
            style={{
              display: "flex",
              fontSize: "1rem",
              fontWeight: "500",
              flexDirection: "row",
              marginRight: "1rem",
            }}
          >
            {"Account:"}
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                marginLeft: "0.25rem",
              }}
            >
              {summaryId}
            </div>
          </div>
        )}

        {/* <IconButton onClick={printHandler} disabled>
          <PrintIcon />
        </IconButton> */}
        <IconButton onClick={exportHandler} sx={{
          backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
          color: theme.palette.text.primary
        }}>
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LocationToolbar;
