import React, { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./TextFieldPasswordStyles.scss";
import { AppContext } from "../../../context/AppContext";
export default function TextFieldPassword(props) {

  const [showPassword, setShowPassword] = React.useState(false);
  const [selectData, setData] = React.useState([]);
  const { onTextChange } = props;
  const appContext = useContext(AppContext);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setData(
      // On fill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onTextChange(value);
  };

  return (
    <Box
      value={selectData}
      onChange={handleChange}
      sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl sx={{
          mt: 1,
          mb: 1,
          borderRadius: 10,
          boxShadow: 0,
          "& .MuiInputBase-root": {
            bgcolor: appContext.darkMode ? "grey" : "#ffffff",
            width: "85vw",
            maxWidth: "25rem",
            height: "3.5rem",
            padding: "1rem",
            "& .MuiInputBase-input": {
              height: "3rem",
            }
          },
          // Autofill background and text color override
          "& input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${appContext.darkMode ? 'grey' : '#ffffff'} inset !important`,
            transition: "background-color 5000s ease-in-out 0s",
          },
        }} variant='outlined'>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ backgroundColor: appContext.darkMode ? '#1c1c1c' : '#ffffff', }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </div>
    </Box>
  );
}
