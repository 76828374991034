import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SummaryMap from "./SummaryMap";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  formatDateBasedOnLocale,
  getCurrentDateYYYYMMDD,
  getCurrentDateYYYYMMDDWithTime,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import LocationToolbar from "./LocationToolbar";
import {
  fetchAccountTransactionsSummaryReport,
  fetchDeviceTransactionsSummaryReport,
} from "../../services/SummaryService";
import Loader from "../loader/Loader";
import {
  errorValidator,
  exportToCsv,
  getLocatedNameByCode,
  getReasonsNameByCode,
} from "../../utils/CommonUtils";
import { AppContext } from "../../context/AppContext";
import {
  DEFAULT_TIMEZONE_OFFSET,
  SUMMARY_ACC2T,
  SUMMARY_DEV2T,
} from "../../constants/Constants";
import { ToastContainer, toast } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import { REPORT_ACC2T, REPORT_DEV2T } from "../../constants/APIConstants";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LocationToolbarMobile from "./LocationToolbarMobile";

const SummaryMapContainer = (props) => {
  const { summaryType, summaryId } = props;
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { getUserReportByAPI } = appContext;

  const [showLoader, setShowLoader] = useState(false);
  const [summaryLocationsData, setSummaryLocationsData] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [dateFilter, setDateFilter] = useState({
    startDate: getDateBySubtractMonth(3),
    endDate: getCurrentDateYYYYMMDD(),
  });

  // const [dateFilter, setDateFilter] = useState({
  //   startDate: "2024/01/01",
  //   endDate: "2024/09/01",
  // });

  const onClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (summaryType === "device") {
      fetchDeviceLocationsSummaryReportData();
    } else if (summaryType === "account") {
      fetchAccountLocationsSummaryReportData();
    }
  }, [dateFilter, selectedReason]);

  const fetchDeviceLocationsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_DEV2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_DEV2T,
        appId: appContext.selectedUserApp.AppId,
        DeviceID: summaryId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        // PageNumber: selectedPage.page,
        // PageSize: selectedPage.pageSize,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ReasonForFailure: selectedReason ? selectedReason.id : 0,
      };
      setShowLoader(true);
      fetchDeviceTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryLocationsData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };
  const fetchAccountLocationsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_ACC2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_ACC2T,
        appId: appContext.selectedUserApp.AppId,
        AccountId: summaryId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        // PageNumber: selectedPage.page,
        // PageSize: selectedPage.pageSize,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ReasonForFailure: selectedReason ? selectedReason.id : 0,
      };
      setShowLoader(true);
      fetchAccountTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryLocationsData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onExportData = () => {
    const strDateTime = getCurrentDateYYYYMMDDWithTime();
    const filename = summaryId + "_lookups_" + strDateTime + ".csv";
    if (summaryType === "device") {
      const data = summaryLocationsData.Data.Results.map((item) => {
        return {
          "Customer (AccountId)": item.AccountId,
          "Transaction Date": formatDateBasedOnLocale(item.Timestamp),
          Status: getReasonsNameByCode(
            item.ReasonForFailure,
            appContext.failedReasons
          ),
          Location: item.Latitude + ", " + item.Longitude,
          "Located By": getLocatedNameByCode(item.GeoLookUpType),
          "Transaction ID": item.GeoReferenceId,
        };
      });

      if (data && data.length > 0) {
        exportToCsv(filename, data);
      }
    } else {
      const data = summaryLocationsData.Data.Results.map((item) => {
        return {
          "Device ID": item.DeviceId,
          "Transaction Date": formatDateBasedOnLocale(item.Timestamp),
          Status: getReasonsNameByCode(
            item.ReasonForFailure,
            appContext.failedReasons
          ),
          Location: item.Latitude + ", " + item.Longitude,
          "Located By": getLocatedNameByCode(item.GeoLookUpType),
          "Transaction ID": item.GeoReferenceId,
        };
      });

      if (data && data.length > 0) {
        exportToCsv(filename, data);
      }
    }
  };

  const onPrintData = () => {
    // window.print();
  };

  const onRefreshData = (data, type) => {
    if (type === 1) {
      setDateFilter({
        ...dateFilter,
        startDate: data,
        endDate: data,
      });
    } else if (type === 2) {
      setSelectedReason(data);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <IconButton onClick={onClickBack}>
          <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        {isDesktop ? (
          <LocationToolbar
            summaryType={summaryType}
            summaryId={summaryId}
            exportHandler={onExportData}
            printHandler={onPrintData}
            refreshDataHandler={onRefreshData}
          />
        ) : (
          <LocationToolbarMobile
            summaryType={summaryType}
            summaryId={summaryId}
            exportHandler={onExportData}
            printHandler={onPrintData}
            refreshDataHandler={onRefreshData}
          />
        )}
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {summaryLocationsData && (
          <SummaryMap
            data={summaryLocationsData.Data.Results}
            summaryType={summaryType}
            summaryId={summaryId}
          />
        )}
      </Box>
      {showLoader && <Loader />}
    </Box>
  );
};

export default SummaryMapContainer;
