export const DRAWER_WIDTH = 240;
export const DEFAULT_ZOOM_LEVEL = 2;
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_TIMEZONE_OFFSET = "+1";
export const BASE_B1T = 1;
export const BASE_B2T = 3;
export const BASE_B3T = 5;
export const DASHBOARD_D1T = 4;
export const DASHBOARD_D2T = 2;
export const SEARCH_S1T = 6;
export const SEARCH_S2T = 7;
export const DEFAULT_MAP_PAGE_SIZE = 2500;


// Dev
// export const SUMMARY_DEV1T = 10;
// export const SUMMARY_DEV2T = 11;
// export const SUMMARY_ACC1T = 12;
// export const SUMMARY_ACC2T = 13;

// Test
export const SUMMARY_DEV1T = 14;
export const SUMMARY_DEV2T = 15;
export const SUMMARY_ACC1T = 16;
export const SUMMARY_ACC2T = 17;

export const TEST_POSITION = 0;
export const USER_INFO = "user_info";
export const JWT_TOKEN = "token";
export const SELECTED_APP = "selected_app";
export const SELECTED_DATE = "selected_date";
export const SELECTED_FAILED_REASON = "selected_reason_failed";

export const COLOURS = {
  geo_colour_main: "#1e86f0",
  geo_colour_main_tint: "#e6f1fc",
  geo_colour_dark_grey: "#808080",
  geo_colour_light_grey: "#ececec",
};
