import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@emotion/react";

const SearchResultToolbar = (props) => {
  const { resultCount, exportHandler, printHandler } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            fontSize: "1rem",
            fontWeight: "500",
            flexDirection: "row",
            color:  theme.palette.text.primary,
          }}
        >
          {"Search Result: (" + resultCount + ")"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          marginRight: "4rem",
        }}
      >
        {resultCount > 0 && (
          <>
            <IconButton onClick={printHandler} disabled>
              <PrintIcon />
            </IconButton>
            <IconButton onClick={exportHandler} sx={{
              backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
              color: theme.palette.text.primary
            }}>
              <DownloadIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchResultToolbar;
