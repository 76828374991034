import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import "./DashboardListViewStyle.scss";
import { formatDateBasedOnLocale } from "../../../utils/DateUtils";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: "#ffffff",
    backgroundColor: theme.palette.mode === "dark" ? "#1c1c1c" : "#ffffff",
    color: theme.palette.text.primary + "!important",
    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[400], ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const getStatus = (param) => {
  return param === 0 ? "Failed" : param === 1 ? "Success" : "Undetermined";
};

const getColorCode = (param) => {
  return param === 0 ? "#D32F2F" : param === 1 ? "#43A047" : "#D32F2F";
};

const DashboardListView = (props) => {
  const {
    data,
    onRefreshData,
    type,
    reasonsForFail,
    showActivity,
    selectedPage,
  } = props;
  const [paginationModel, setPaginationModel] = useState({
    page: selectedPage ? selectedPage.page - 1 : 0,
    pageSize: data.PageSize,
  });

  const [activity, setActivity] = useState(false);

  useEffect(() => {
    setActivity(showActivity);
  }, [showActivity]);

  const getNameByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return reasonCode;

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );

    return itemFound?.label;
  };

  const getColorCodeByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return null;

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );
    return itemFound ? itemFound.color : "#9dc5ed";
  };

  const getColumns = () => {
    if (activity) {
      return [
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getNameByReasonCode(params.row.ReasonForFailure)}
              </div>
            );
          },
        },
        {
          field: "AccountId",
          headerName: "Customer (Account ID)",
          width: 180,
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 380,
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Date & Time",
          width: 160,
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
      ];
    } else {
      return [
        {
          field: "AccountId",
          headerName: "Customer (Account ID)",
          width: 180,
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 380,
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Date & Time",
          width: 160,
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                  // backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
                  // color: theme.palette.text.primary
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getNameByReasonCode(params.row.ReasonForFailure)}
              </div>
            );
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          backgroundColor:
            theme.palette.mode === "dark" ? "#1c1c1c" : "#d8d8d8",
          color: theme.palette.text.primary,
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "ReasonForLookup",
          headerName: "Reason for Lookup",
          width: 200,
        },
      ];
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        /> */}
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
      </GridToolbarContainer>
    );
  }
  const theme = useTheme();
  return (
    <div
      style={{
        height: "70vh",
        width: "100%",
        backgroundColor: theme.palette.mode === "dark" ? "#1c1c1c" : "#d8d8d8",
        color: theme.palette.text.primary,
      }}
    >
      <StripedDataGrid
        rows={data.Results}
        columns={getColumns()}
        rowCount={data.TotalRecords}
        pagination
        paginationMode="server"
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        getRowId={(row) => row.GeoReferenceId}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        paginationModel={paginationModel}
        pageSizeOptions={[100]}
        onPaginationModelChange={(newPaginationModel) => {
          setPaginationModel(newPaginationModel);
          if (onRefreshData) {
            onRefreshData(
              { ...newPaginationModel, page: newPaginationModel.page + 1 },
              type
            );
          }
        }}
        sx={{
          "& .MuiTablePagination-displayedRows": {
            color: theme.palette.text.primary,
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        getRowHeight={() => "auto"}
      />
    </div>
  );
};

export default DashboardListView;
