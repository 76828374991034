import React, { useState, useContext, useEffect, useRef } from "react";
import "./DashboardStyles.scss";
import Grid from "@mui/material/Grid";
import ACTPieCard from "../charts/ACTPieCard";
import DashboardClusterMap from "./maps/clustermap/DashboardClusterMap";
import DashboardListView from "./listview/DashboardListView";
import { AppContext } from "../../context/AppContext";
import Loader from "../loader/Loader";
import Box from "@mui/material/Box";
import {
  fetchDashboardReport,
  fetchActivities,
  fetchDevices,
  fetchFailedReasons,
  fetchListReasonsForFail,
} from "../../services/HomeService";

import {
  DASHBOARD_D1T,
  BASE_B1T,
  BASE_B2T,
  BASE_B3T,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_OFFSET,
  SELECTED_APP,
  SELECTED_DATE,
  SELECTED_FAILED_REASON,
  DEFAULT_MAP_PAGE_SIZE,
} from "../../constants/Constants";
import { getRandomHexColor } from "../../utils/ColorUtils";
import {
  getFormattedCurrentDate,
  subtractHours,
  subtractMinutes,
  getCurrentDateYYYYMMDD,
} from "../../utils/DateUtils";
import {
  isTokenError,
  showCatchedExceptionOnToast,
  showServerExceptionOnToast,
} from "../../utils/ExceptionUtils";
import { removeAuthInfo } from "../../utils/AuthUtils";
import DashboardSuperClusterMap from "./maps/superclustermap/DashboardSuperClusterMap";
import DashboardSuperClusterMapWithoutPagination from "./maps/superclustermap/DashboardSuperClusterMap";
import { getColorByDeviceType } from "../../utils/ColorUtils";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DashboardHeaderMobile from "./DashboardHeaderMobile";
import DashboardTabHeader from "./DashboardTabHeader";
import DashboardToolbarDesktop from "./DashboardToolbarDesktop";
import Switch from "@mui/material/Switch";
import { ToastContainer, toast } from "material-react-toastify";
import ResultsImage from "../../assets/images/search-results.png";
import GenericError from "../GenericError/GenericError";
import {
  REPORT_B1T,
  REPORT_B2T,
  REPORT_B3T,
  REPORT_D1T,
} from "../../constants/APIConstants";

const timeBasedFilterOptions = [
  { id: 1, label: "Last 30 Minutes" },
  { id: 2, label: "Last 1 Hour" },
  { id: 3, label: "Last 2 Hours" },
  { id: 4, label: "Last 5 Hours" },
  { id: 5, label: "Last 8 Hours" },
];

const errorData = {
  title:
    "We couldn't find any results that match your criteria. Please make some edits and try again.",
  image: ResultsImage,
};

const Dashboard = () => {
  const appContext = useContext(AppContext);
  const { dashboardStates, setDashboardData, getUserReportByAPI } = appContext;

  const [showLoader, setShowLoader] = useState(false);
  const [selectedUserApp, setSelectedUserApp] = useState(
    dashboardStates["user_apps"] || {
      id: appContext.userDetails.Data.UserApps[0].AppId,
      label: appContext.userDetails.Data.UserApps[0].AppName,
      ...appContext.userDetails.Data.UserApps[0],
    }
  );
  const [reportsD1TMap, setReportsD1TMap] = useState(null);
  const [reportsD1TList, setReportsD1TList] = useState(null);

  const [reportsD2T, setReportsD2T] = useState(null);
  const [reportsB1T, setReportsB1T] = useState(null);
  const [reportsB2T, setReportsB2T] = useState(null);
  const [reportsB3T, setReportsB3T] = useState(null);
  const [listReasonsForFail, setListReasonsForFail] = useState(null);
  const [selectedReason, setSelectedReason] = useState(
    dashboardStates["failed_reasons"] || null
  );
  const [selectedPage, setSelectedPage] = useState(
    dashboardStates["page"] || {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    }
  );
  const [userApps, setUserApps] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const intervalRef = useRef(null);

  const [showActivity, setShowActivity] = useState(false);

  // const [dateFilter, setDateFilter] = useState({
  //   startDate: "2024/01/01",
  //   endDate: "2024/09/01",
  // });

  const [dateFilter, setDateFilter] = useState(
    dashboardStates["date"] || {
      startDate: getCurrentDateYYYYMMDD(),
      endDate: getCurrentDateYYYYMMDD(),
    }
  );

  useEffect(() => {
    getUserApps();
    fetchListReasonForFail();
    return () => {
      const intervalId = intervalRef.current;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    fetchDashboardD1TReportForMap();
    fetchDashboardB1TReport();
    fetchDashboardB2TReport();
    fetchDashboardB3TReport();
  }, [dateFilter, selectedUserApp, refresh]);

  useEffect(() => {
    fetchDashboardD1TReportForList();
  }, [dateFilter, selectedPage, selectedUserApp, selectedReason, refresh]);

  useEffect(() => {
    fetchDashboardD1TReportForMap();
  }, [selectedReason]);

  const fetchDashboardD1TReportForMap = () => {
    try {
      const report = getUserReportByAPI(REPORT_D1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : DASHBOARD_D1T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ListView: "true",
        PageNumber: 1,
        PageSize: DEFAULT_MAP_PAGE_SIZE,
        ReasonForFailure: selectedReason ? selectedReason.id : -2,
      };

      setShowLoader(true);
      fetchDashboardReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setReportsD1TMap(response);
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardD1TReportForList = () => {
    try {
      const report = getUserReportByAPI(REPORT_D1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : DASHBOARD_D1T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ListView: "true",
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
        ReasonForFailure: selectedReason ? selectedReason.id : -2,
      };

      setShowLoader(true);
      fetchDashboardReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setReportsD1TList(response);
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardB1TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B1T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchActivities(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              if (data.Failed === 0 && data.Passed === 0) {
                setReportsB1T({ results: [] });
              } else {
                const total = data.Failed + data.Passed;
                const percentFailed = (data.Failed / total) * 100;
                const percentSuccess = (data.Passed / total) * 100;

                const results = [
                  {
                    value: data.Failed,
                    title: "Failed " + percentFailed.toFixed(2) + "%",
                    color: "#D32F2F",
                  },
                  {
                    value: data.Passed,
                    title: "Passed " + percentSuccess.toFixed(2) + "%",
                    color: "#43A047",
                  },
                ];
                setReportsB1T({ results: results });
              }
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB1T({ results: null });
        })
        .finally(() => { });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardB2TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B2T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchDevices(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              const results = data.map((item) => {
                return {
                  value: item.TotalNumber,
                  title: item.DeviceType,
                  color: getColorByDeviceType(item.DeviceType),
                };
              });
              setReportsB2T({ results: results });
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB2T({ results: null });
        })
        .finally(() => { });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardB3TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B3T);

      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B3T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchFailedReasons(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              const results = data.map((item) => {
                return {
                  id: item.ReasonForFailure,
                  value: item.TotalNumber,
                  label: item.ReasonForFailure,
                  color: getRandomHexColor(),
                };
              });
              setReportsB3T({ results: results });
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB3T({ results: null });
        })
        .finally(() => { });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchListReasonForFail = () => {
    try {
      const payload = {
        JWTid: appContext.userDetails.JWTid,
      };

      fetchListReasonsForFail(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data && response.Data.length > 0) {
              const data = response.Data;
              const results = data.map((item) => {
                return {
                  ...item,
                  label: item.FriendlyName,
                  id: item.Code,
                  key: item.Name,
                  color: item.Color,
                };
              });
              setListReasonsForFail(results);
              appContext.setFailedReasons(data);
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => { });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const dashboardHeaderButtonSelect = (value) => {
    if (value === 0) {
      appContext.setSelectedTab(0);
    } else {
      appContext.setSelectedTab(1);
    }
  };

  const getUserApps = () => {
    if (appContext.userDetails) {
      const apps = appContext.userDetails.Data.UserApps.map((item) => {
        return { id: item.AppId, label: item.AppName, ...item };
      });
      setUserApps(apps);
    }
  };

  const prepareFilterDate = (data) => {
    if (data.id === 1) {
      setDateFilter({
        ...dateFilter,
        startDate: subtractMinutes(30),
        endDate: getFormattedCurrentDate(),
      });
    } else if (data.id === 2) {
      setDateFilter({
        ...dateFilter,
        startDate: subtractHours(1),
        endDate: getFormattedCurrentDate(),
      });
    } else if (data.id === 3) {
      setDateFilter({
        ...dateFilter,
        startDate: subtractHours(2),
        endDate: getFormattedCurrentDate(),
      });
    } else if (data.id === 4) {
      setDateFilter({
        ...dateFilter,
        startDate: subtractHours(5),
        endDate: getFormattedCurrentDate(),
      });
    } else if (data.id === 5) {
      setDateFilter({
        ...dateFilter,
        startDate: subtractHours(8),
        endDate: getFormattedCurrentDate(),
      });
    }
  };

  const refreshData = (data, type) => {
    if (!data) return;
    if (type === 1) {
      prepareFilterDate(data);
    } else if (type === 2) {
      setSelectedUserApp(data);
      appContext.setSelectedUserApp(data);
      setSelectedPage({
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      localStorage.setItem(SELECTED_APP, JSON.stringify(data));
    } else if (type === 3) {
      setDateFilter({
        ...dateFilter,
        startDate: data,
        endDate: data,
      });
      localStorage.setItem(
        SELECTED_DATE,
        JSON.stringify({
          startDate: data,
          endDate: data,
        })
      );
    } else if (type === 4) {
      setDashboardData("page", data);
      setSelectedPage(data);
    } else if (type === 5) {
      setSelectedReason(data);
      setSelectedPage({
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      localStorage.setItem(SELECTED_FAILED_REASON, JSON.stringify(data));
    } else if (type === 6) {
      setRefresh(Math.random());
    }
  };

  const validateAndShowError = (status) => {
    const tokenError = isTokenError(status);
    if (tokenError) {
      removeAuthInfo();
      appContext.setUserDetails(null);
    } else {
      showServerExceptionOnToast(toast, 1, status);
    }
  };

  const getB3TDataWithMappedColor = () => {
    if (!reportsB3T) return null;
    const values = reportsB3T.results.map((item) => {
      const itemFound = listReasonsForFail.find(
        (reasonItem) => reasonItem.id === item.label
      );
      if (itemFound) {
        return {
          id: item.id,
          value: item.value,
          color: itemFound.color,
          title: itemFound.label,
        };
      }
    });
    const sortedValues = values.sort((a, b) => b.value - a.value);
    return { results: sortedValues };
  };

  const handleSwitchToggle = () => {
    // Refresh data on every 5 minutes
    if (autoRefresh) {
      const intervalId = intervalRef.current;
      clearInterval(intervalId);
    } else {
      const intervalId = setInterval(() => {
        setRefresh(Math.random());
      }, 300000);
      intervalRef.current = intervalId;
    }
    setAutoRefresh(!autoRefresh);
  };

  const onActivityClick = () => {
    appContext.setSelectedTab(1);
    setShowActivity(true);
  };

  return (
    <Box style={{ display: "flex", width: "100%" }}>
      <div className="dashboard-container" style={{ backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff' }}>
        <div className="map-container">
          {!isDesktop ? (
            <>
              <DashboardTabHeader
                onTabSelect={dashboardHeaderButtonSelect}
                selectedTabItem={appContext.selectedTab}
              />
              <DashboardHeaderMobile
                onTabSelect={dashboardHeaderButtonSelect}
                selectedTabItem={appContext.selectedTab}
                onRefreshData={refreshData}
                reasonsForFail={listReasonsForFail}
                userApps={userApps}
                type={3}
              />
            </>
          ) : (
            <>
              <DashboardTabHeader
                onTabSelect={dashboardHeaderButtonSelect}
                selectedTabItem={appContext.selectedTab}
              />
              <DashboardToolbarDesktop
                reasonsForFail={listReasonsForFail}
                userApps={userApps}
                onRefreshData={refreshData}
                type={3}
              />
            </>
          )}

          <div
            style={{ display: appContext.selectedTab === 0 ? "block" : "none" }}
          >
            {reportsD1TMap && listReasonsForFail && (
              <DashboardSuperClusterMap
                data={reportsD1TMap}
                selectedApp={selectedUserApp}
                dateFilter={dateFilter}
                selectedReason={selectedReason}
                reasonsForFail={listReasonsForFail}
              />
            )}
          </div>
          {/* {appContext.selectedTab === 0 && reportsD1TMap && (
            <DashboardSuperClusterMapWithPagination
              data={reportsD1TMap}
              selectedApp={selectedUserApp}
              dateFilter={dateFilter}
              selectedReason={selectedReason}
              reasonsForFail={listReasonsForFail}
            />
          )} */}
          {/* <div
            style={{ display: appContext.selectedTab === 0 ? "block" : "none" }}
          >
            {reportsD1TMap && listReasonsForFail && (
              <DashboardClusterMap
                data={reportsD1TMap.Data.Results}
                selectedApp={selectedUserApp}
                onRefreshData={refreshData}
                type={5}
                reasonsForFail={listReasonsForFail}
              />
            )}
          </div> */}

          <div
            style={{ display: appContext.selectedTab === 1 ? "block" : "none" }}
          >
            {reportsD1TList &&
              reportsD1TList.Data &&
              reportsD1TList.Data.Results.length > 0 && (
                <DashboardListView
                  data={reportsD1TList.Data}
                  selectedApp={selectedUserApp}
                  onRefreshData={refreshData}
                  reasonsForFail={listReasonsForFail}
                  type={4}
                  showActivity={showActivity}
                  selectedPage={selectedPage}
                />
              )}
          </div>

          {appContext.selectedTab === 1 &&
            reportsD1TList &&
            reportsD1TList.Data &&
            reportsD1TList.Data.Results.length === 0 && (
              <GenericError data={errorData} />
            )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: ".25rem",
            backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff',
            color: theme.palette.text.primary,
          }}
        >
          Auto updates <Switch onClick={() => handleSwitchToggle()} />
        </div>
        {appContext.selectedTab === 0 && (
          <div style={{
            display: "flex", width: "100%", backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff',
            color: theme.palette.text.primary,
          }}>
            <Grid
              sx={{ flexGrow: 1 }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
              style={{ paddingTop: "1rem" }}
            >
              {reportsB1T && (
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <ACTPieCard
                    data={reportsB1T}
                    title={"Activity"}
                    link={true}
                    activityHandler={onActivityClick}
                  />
                </Grid>
              )}
              {reportsB2T && (
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <ACTPieCard data={reportsB2T} title={"Devices"} />
                </Grid>
              )}
              {reportsB3T && listReasonsForFail && (
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <ACTPieCard
                    data={getB3TDataWithMappedColor()}
                    title={"Reasons for fail"}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
    </Box>
  );
};

export default Dashboard;
