import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Main } from "../components/shared/Main";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import BlockedAccountContainer from "../components/blockedAccounts/BlockedAccountContainer";

const BlockedAccounts = () => {
    const appContext = useContext(AppContext);

    return (
      <Main
        open={appContext.drawerOpen}
        sx={{
          overflowX: "hidden",
          height: '100vh' 
        }}
      >
        <DrawerHeader />
        <BlockedAccountContainer />
      </Main>
    );
}

export default BlockedAccounts;