import { IconButton } from "@mui/material";
import { getReasonsNameByCode } from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { CopyAllRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";

const ACTTransaction = ({
  selectedGeoRefId,
  points,
  failedReasons,
  onCopyData,
  onViewSimilarLocations,
}) => {
  const result = points.find(
    (item) => item.GeoReferenceId === selectedGeoRefId
  );

  const latLngGroup = points.filter(
    (item) =>
      item.Latitude === result.Latitude &&
      item.Longitude === result.Longitude &&
      item.GeoReferenceId !== result.GeoReferenceId
  );
  return (
    <div className="cluster-data-container">
      <div className="cluster-data-panel">
        <strong>Date & Time:</strong>{" "}
        {formatDateBasedOnLocale(result.Timestamp)}
      </div>

      <div className="cluster-data-panel copy">
        <div>
          <strong>Device ID:</strong> {result.DeviceId}
        </div>
        <div>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() => onCopyData(result.DeviceId)}
          >
            <CopyAllRounded className="copy-icon" />
          </IconButton>
        </div>
      </div>

      <div className="cluster-data-panel copy">
        <div>
          <strong>Account ID:</strong> {result.AccountId}
        </div>
        <div>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() => onCopyData(result.AccountId)}
          >
            <CopyAllRounded className="copy-icon" />
          </IconButton>
        </div>
      </div>

      <div className="cluster-data-panel copy">
        <div>
          <strong>Transaction ID:</strong> {result.GeoReferenceId}
        </div>
        <div>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() => onCopyData(result.GeoReferenceId)}
          >
            <CopyAllRounded className="copy-icon" />
          </IconButton>
        </div>
      </div>

      <div className="cluster-data-panel copy">
        <div>
          <strong>Lat & Long:</strong> {result.Latitude}, {result.Longitude}
        </div>
        <div>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() => onCopyData(result.Latitude + "," + result.Longitude)}
          >
            <CopyAllRounded className="copy-icon" />
          </IconButton>
        </div>
      </div>

      <div className="cluster-data-panel">
        <strong>Status: </strong>{" "}
        {getReasonsNameByCode(result.ReasonForFailure, failedReasons)} <br></br>
      </div>

      <br></br>

      <div className="cluster-data-panel copy">
        <Link
          to={"/summary/device/" + result.DeviceId}
          state={{ type: "device", id: result.DeviceId }}
        >
          {"view all device activity"}
        </Link>
        {latLngGroup && latLngGroup.length > 0 && (
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => onViewSimilarLocations(e, latLngGroup)}
          >
            view similar locations
          </div>
        )}
      </div>
    </div>
  );
};

export default ACTTransaction;
