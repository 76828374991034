import { React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ImageIcon from "@mui/icons-material/Image";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../../context/AppContext";
import geosafeLogo from "../../assets/images/geosafe-logo.png";
import geosafeLogoWhite from "../../assets/images/geosafe-logo-white.png";

import "./SideMenuDrawerStyles.scss";

const SIDE_MENU_DATA = [
  {
    name: "Dashboard",
    icon: <DashboardIcon className="link-icon" />,
    link: "/dashboard",
  },
  {
    name: "Users",
    icon: <GroupIcon className="link-icon-disabled" />,
    link: "/users",
  },
  {
    name: "Reports",
    icon: <ShoppingBasketIcon className="link-icon" />,
    link: "/reports",
  },
  {
    name: "Glossary",
    icon: <FormatSizeIcon className="link-icon" />,
    link: "/glossary",
  },
  {
    name: "Contact Support",
    icon: <ImageIcon className="link-icon-disabled" />,
    link: "/contact-support",
  },
];

const SAVED_REPORT_DATA = [
  {
    name: "Last 7 Days",
    icon: <CalendarTodayIcon className="link-icon" />,
    link: "/last-seven-days",
  },
  {
    name: "Last 30 Days",
    icon: <CalendarTodayIcon className="link-icon" />,
    link: "/last-thirty-days",
  },
];

// {
//   name: "Blocked Accounts",
//   icon: <CalendarTodayIcon className="link-icon" />,
//   link: "/blocked-accounts",
// },
// {
//   name: "Blocked Devices",
//   icon: <CalendarTodayIcon className="link-icon" />,
//   link: "/blocked-devices",
// },
// {
//   name: "VPN detected",
//   icon: <CalendarTodayIcon className="link-icon" />,
//   link: "/vpn-detected",
// },

const SideMenuDrawer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // true if screen is md (medium) or larger
  const location = useLocation();

  const onSideMenuItemClick = (path) => {
    navigate(path);
    if (!isDesktop) {
      appContext.setDrawerOpen(false);
    }
  };

  return (
    <>
      <div className="nav-menu-top">
        <div className="nav-side-bar"></div>
        <img
          src={theme.palette.mode === "dark" ? geosafeLogoWhite : geosafeLogo}
          alt="Geosafe logo"
          className="side-logo"
        />
      </div>

      <List>
        {SIDE_MENU_DATA.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={item.link === location.pathname ? "active-link" : ""}
          >
            <ListItemButton
              onClick={() => onSideMenuItemClick(item.link)}
              disabled={
                index === 0 || index === 2 || index === 3
                  ? ""
                  : "disable-cursor"
              }
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                className={
                  index === 0 || index === 2 || index === 3
                    ? ""
                    : "disabled-colour"
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className="nav-reports-list">
        <p
          className="nav-reports-subhead"
          style={{ color: theme.palette.text.primary }}
        >
          Saved Reports
        </p>
      </div>
      <List>
        {SAVED_REPORT_DATA.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={item.link === location.pathname ? "active-link" : ""}
          >
            <ListItemButton onClick={() => onSideMenuItemClick(item.link)}>
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  color: "#aaaaaa",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SideMenuDrawer;
