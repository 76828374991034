import "./Legends.scss";

const ACTLegends = (props) => {
  const { data, handleClick } = props;

  return (
    <div>
      {data &&
        data.map((item, index) => {
          if (item?.id === -1) {
            return (
              <div
                key={index}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: ".7rem",
                }}
                onClick={handleClick}
              >
                show more...
              </div>
            );
          } else {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  gap: ".4rem",
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: ".7rem",
                    minWidth: "1rem",
                    // marginTop: '-3px'
                  }}
                >
                  {item?.value}
                </div>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item?.color,
                      minHeight: "1rem",
                      maxHeight: "1rem",
                      minWidth: "1rem",
                      maxWidth: "1rem",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "5px",
                      fontWeight: 400,
                      fontSize: ".7rem",
                    }}
                  >
                    {item?.title}
                  </div>
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};

export default ACTLegends;
