import { useState, useContext, useEffect } from "react";
import Slide from "@mui/material/Slide";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import { COLOURS, DEFAULT_PAGE_SIZE } from "../../../constants/Constants";
import "./AnimatedSearchTabStyles.scss";
import { bgBlur } from "./css";
import Iconify from "./iconify";
import { AppContext } from "../../../context/AppContext";
import { SEARCH_S1T } from "../../../constants/Constants";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { fetchDashboardSearchData } from "../../../services/SearchService";
import { REPORT_S1T } from "../../../constants/APIConstants";
import { useTheme } from "@emotion/react";

const basicSearchParam = {
  reportId: "",
  appId: "",
  GeoReferenceId: "",
  AccountId: "",
  DeviceId: "",
  Latitude: "",
  Longitude: "",
  IpAddress: "",
  ExactMatch: "",
  PageNumber: "",
  PageSize: "",
  JWTid: "",
};
const HEADER_MOBILE = 92;
const HEADER_DESKTOP = 64;

const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const menuItems = [
  {
    id: 0,
    name: "Transaction ID",
    key: "GeoReferenceId",
  },
  {
    id: 1,
    name: "Account ID",
    key: "AccountId",
  },
  {
    id: 2,
    name: "Device ID",
    key: "DeviceId",
  },
  {
    id: 3,
    name: "Location Co-ordinates",
    key: "LatLng",
  },
  {
    id: 4,
    name: "IP address",
    key: "IpAddress",
  },
];

export default function Searchbar(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { toggleAdvanced, searchItem } = props;
  const appContext = useContext(AppContext);
  const { getUserReportByAPI } = appContext;
  const theme = useTheme();
  const [dropDownValue, setDropDownValue] = useState(
    appContext.searchParams.basic.selectedSearchType
  );
  const [inputSearchBox, setInputSearchBox] = useState(
    appContext.searchParams.basic.searchText
  );
  const [basicSearch, setBasicSearch] = useState(basicSearchParam);
  const [fieldValidation, setFieldValidation] = useState(false);
  const [basicSearchData, setBasicSearchData] = useState();

  useEffect(() => { }, [basicSearchData]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    // Check if the click is inside the dropdown
    if (event?.target?.classList.length === 0) {
      return;
    }
    appContext.setSearchParams({
      ...appContext.searchParams,
      basic: {
        ...appContext.searchParams.basic,
        searchText: inputSearchBox,
        selectedSearchType: dropDownValue,
      },
    });
    setOpen(false);
    // setInputSearchBox("");
    // setDropDownValue("GeoReferenceId");
    // setBasicSearch(basicSearchParam);
    // setFieldValidation(false);
  };

  const searchBy = (inp, fieldName) => {
    let searchInput = inp.target.value;
    if (fieldName === "dropDown") {
      setDropDownValue(searchInput);
      setInputSearchBox("");
      setFieldValidation(false);
      setBasicSearch(null);
      searchItem(null);
      return;
    }
    setFieldValidation(false);
    setInputSearchBox(inp.target.value);
    if (dropDownValue === "LatLng") {
      const location = searchInput.split(",");
      if (location.length > 1) {
        setBasicSearch({
          Latitude: location[0].trim(),
          Longitude: location[1].trim(),
        });
      }
    } else {
      setBasicSearch({ [dropDownValue]: searchInput.trim() });
    }
    if (
      searchItem &&
      (dropDownValue === "AccountId" ||
        dropDownValue === "DeviceId" ||
        dropDownValue === "LatLng" ||
        dropDownValue === "IpAddress")
    ) {
      searchItem({ key: dropDownValue, value: searchInput.trim() });
    }
  };

  const dataSearch = () => {
    // if inputSearchBox field values are empty or dropDownValue value is default then prevent submission and show error
    if (inputSearchBox === "") {
      //error state as true and return
      setFieldValidation(true);
    } else {
      setFieldValidation(false);
      fetchSearchData();
    }
  };

  const fetchSearchData = async () => {
    let payload = { ...basicSearch };
    const report = getUserReportByAPI(REPORT_S1T);

    payload.reportId = report ? report.ReportId : SEARCH_S1T;
    payload.appId = appContext.selectedUserApp.AppId;
    payload.ExactMatch = false;
    payload.PageNumber = 1;
    payload.PageSize = DEFAULT_PAGE_SIZE;
    payload.JWTid = appContext.userDetails.JWTid;

    // if (dropDownValue === "AccountId") {
    //   const response = await fetchDashboardSearchData(payload);

    //   if (response.Data.Results.length === 1) {
    //     const data = response.Data.Results[0];
    //     navigate("/summary/account/" + data.AccountId, {
    //       state: { type: "account", id: data.AccountId },
    //     });
    //   }
    // }
    setBasicSearch(payload);
    var gotoDevicePage = false;
    if (
      dropDownValue === "DeviceId" &&
      (payload.DeviceId.length === 16 || payload.DeviceId.length === 36)
    ) {
      gotoDevicePage = true;
    }
    // if (dropDownValue === "AccountId") {
    //   navigate("/summary/account/" + payload.AccountId, {
    //     state: { type: "account", id: payload.AccountId },
    //   });
    // }
    if (dropDownValue === "DeviceId" && gotoDevicePage) {
      navigate("/summary/device/" + payload.DeviceId, {
        state: { type: "device", id: payload.DeviceId },
      });
    } else {
      navigate("/search", {
        state: {
          searchParam: payload,
          advanceSearch: false,
          type: dropDownValue,
        },
      });
    }
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <div className="search-input-container">
              <SearchIcon
                sx={{ color: theme.palette.text.primary, mr: 0.75, pt: 0.25, fontSize: 30 }}
              />
              <Input
                error={fieldValidation}
                variant="filled"
                autoFocus
                fullWidth
                placeholder="Search lookup"
                onChange={(inp) => searchBy(inp, "textField")}
                value={inputSearchBox}
                sx={{
                  mr: 1, fontWeight: "fontWeightMed", height: 32, input: {
                    color: theme.palette.text.primary + ' !important',
                    "&::placeholder": {
                      color: theme.palette.text.primary,
                      opacity: 1, 
                    },
                  }
                }}
              />
            </div>

            <div className="modal-parent-center">
              <div className="modal-parent-input">
                <FormControl fullWidth sx={{ m: 1, minWidth: 205 }}>
                  <InputLabel id="select-label">Select</InputLabel>
                  <Select
                    error={fieldValidation}
                    value={dropDownValue}
                    onChange={(inp) => searchBy(inp, "dropDown")}
                    labelId="select-label"
                    id="Select"
                    label="Select"
                    sx={{
                      paddingLeft: 1.5,
                      height: 42,
                      "& .MuiSelect-select": {
                        color: theme.palette.text.primary + '!important', 
                      },
                    }}
                  >
                    {menuItems.map((item) => {
                      return (
                        <MenuItem key={item.key} value={item.key} style={{color: theme.palette.text.primary}}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="button-container">
              <Button
                variant="contained"
                onClick={dataSearch}
                sx={{
                  color: COLOURS.geo_colour_main,
                  bgcolor: COLOURS.geo_colour_main_tint,
                  borderColor: COLOURS.geo_colour_main,
                  border: "0.09rem solid",
                  width: "10rem",
                  boxShadow: "none",
                  cursor: "pointer",

                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: COLOURS.geo_colour_main,
                    boxShadow: "none",
                  },
                }}
              >
                Search
              </Button>
              <div>
                {dropDownValue !== "GeoReferenceId" &&
                  dropDownValue !== "GeoReferenceId" && (
                    <div className="modal-advance-panel">
                      <button onClick={toggleAdvanced}>advanced search</button>
                    </div>
                  )}
              </div>
            </div>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
