import React, { useContext } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./TextFieldOutlinedEmailInputStyles.scss";
import { emailValidation } from "../../../utils/ValidatorUtils";
import { AppContext } from "../../../context/AppContext";

export default function TextFieldOutlinedEmailInput(props) {
  const { placeHolderText, onTextChange } = props;
  const [selectData, setData] = React.useState([]);
  const [message, setMessage] = useState(null);
  const appContext = useContext(AppContext);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setData(
      // On fill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    const valid = emailValidation(value);
    if (value.length === 0) {
      setMessage(null);
    } else if (!valid) {
      setMessage("Invalid Email Id");
    } else {
      setMessage(null);
    }
    onTextChange(value, valid);
  };

  return (
    <Box
      component="form"
      value={selectData}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <p>{placeHolderText}</p>;
        }
        return selected.join();
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label={placeHolderText}
        sx={{
          mt: 1,
          mb: 1,
          borderRadius: 10,
          boxShadow: 0,

          "& .MuiInputBase-root": {
            bgcolor: appContext.darkMode ? "grey" : "#ffffff",
            width: "85vw",
            maxWidth: "25rem",
            height: "3.5rem",
            padding: "1rem",
            "& .MuiInputBase-input": {
              height: "3rem",
            },
          },
          // Autofill background and text color override
          "& input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${appContext.darkMode ? 'grey' : '#ffffff'} inset !important`,
            transition: "background-color 5000s ease-in-out 0s",
          },
        }}
        variant='outlined'
      />
      {emailValidation.value ? "" : <p className="error-message">{message}</p>}
    </Box>
  );
}
