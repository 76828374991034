import React, { useState } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./CommonListViewStyles.scss"; // Optional: Only include if custom styles are needed
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff',
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[400], ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export data" },
          button: { variant: "outlined" },
        }}
      />
    </GridToolbarContainer>
  );
}

const CommonListView = ({
  rows,
  columns,
  rowCount,
  pageSize,
  onRefreshData,
  getRowId,
  height = "70vh",
  checkboxSelection = true,
  paginationMode = "server",
  toolbar = GridToolbar,
  onRowSelection,
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize,
  });
  const theme = useTheme();
  return (
    <div style={{
      height: height, width: "100%", backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
      color: theme.palette.text.primary
    }}>
      <StripedDataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pagination
        disableRowSelectionOnClick
        paginationMode={paginationMode}
        checkboxSelection={checkboxSelection}
        slots={{ toolbar }}
        getRowId={getRowId}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        pageSizeOptions={[pageSize]}
        paginationModel={paginationModel}
        onPaginationModelChange={(newPaginationModel) => {
          setPaginationModel(newPaginationModel);
          if (onRefreshData) {
            onRefreshData({
              ...newPaginationModel,
              page: newPaginationModel.page + 1,
            });
          }
        }}
        onRowSelectionModelChange={onRowSelection}
        sx={{
          '& .MuiTablePagination-displayedRows': {
            color: theme.palette.text.primary,
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        getRowHeight={() => 'auto'}
      />
    </div>
  );
};

CommonListView.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  onRefreshData: PropTypes.func,
  getRowId: PropTypes.func.isRequired,
  height: PropTypes.string,
  checkboxSelection: PropTypes.bool,
  paginationMode: PropTypes.string,
  toolbar: PropTypes.node,
  onRowSelection: PropTypes.func,
};

export default CommonListView;
