import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
import { fetchDashboardReport } from "../../services/HomeService";
import { toast, ToastContainer } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import { REPORT_D1T } from "../../constants/APIConstants";
import {
  DASHBOARD_D1T,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_OFFSET,
} from "../../constants/Constants";
import {
  getCurrentDateYYYYMMDD,
  getDayBySubtractingNumDays,
} from "../../utils/DateUtils";
import Loader from "../loader/Loader";
import CommonListView from "../commonListView/CommonListView";
import { getSavedReportDataGridColumns } from "./SavedReportDataGridColumns";
import { useTheme } from "@emotion/react";

import TransactionImage from "../../assets/images/search-transactions.png";
import GenericError from "../GenericError/GenericError";

const noDataReasons = {
  title:
    "We couldn't find any transactions.",
  image: TransactionImage,
};

const SavedReportContainer = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();

  const { selectedUserApp, getUserReportByAPI } = appContext;
  const [showLoader, setShowLoader] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [selectedPage, setSelectedPage] = useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    fetchLastSevenDaysReportData();
  }, [selectedPage, location]);

  const fetchLastSevenDaysReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_D1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : DASHBOARD_D1T,
        appId: selectedUserApp.AppId,
        startDate: getDayBySubtractingNumDays(
          location.pathname === "/last-seven-days" ? 7 : 30
        ),
        endDate: getCurrentDateYYYYMMDD(),
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ListView: "true",
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
      };
      setShowLoader(true);
      fetchDashboardReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setReportData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onRefreshData = (data, type) => {
    setSelectedPage(data);
  };

  const reportDuration = location.pathname === "/last-seven-days" ? "7" : "30";
  const theme = useTheme();
  return (
    <>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "500",
          marginBottom: "0.5rem",
          color: theme.palette.text.primary 
        }}
      >
        Last {reportDuration} Days
      </Typography>
      {reportData && reportData.Data.Results.length > 0 && (
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <CommonListView
            rows={reportData.Data.Results}
            columns={getSavedReportDataGridColumns(appContext.failedReasons, theme)}
            rowCount={reportData.Data.TotalRecords}
            pageSize={100}
            onRefreshData={(paginationModel) =>
              onRefreshData(paginationModel, 2)
            }
            getRowId={(row) => row.GeoReferenceId}
            // height={tabIndex === 1 ? "70vh" : "50vh"}
          />
        </Box>
      )}
      {reportData && reportData.Data.Results.length === 0 && (
        <GenericError data={noDataReasons} />
      )}

      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default SavedReportContainer;
