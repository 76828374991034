import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import "../../src/components/forgottenPassword/ForgottenPassword.scss";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import TextFieldOutlinedInput from "../components/shared/TextFieldOutlinedInput/TextFieldOutlinedInput";
import { saveUserInfo } from "../utils/AuthUtils";
import Loader from "../components/loader/Loader";
import TextFieldOutlinedEmailInput from "../components/shared/TextFieldOutlinedEmailInput/TextFieldOutlinedEmailInput";

export default function ForgottenPassword() {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLasttName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState("");
  const [isFilledFirstName, setIsFilledFirstName] = useState(false);
  const [isFilledLastName, setIsFilledLastName] = useState(false);

  const from = location.state?.from?.pathname || "/";
  const [showLoader, setShowLoader] = useState("");

  //   const handleSubmit = (event) => {
  //       event.preventDefault();
  //       try {
  //           const payload = {
  //               firstname: firstName,
  //               lastname: lastName,
  //               email: emailAddress,
  //           };

  //           setShowLoader(true);
  //           xxxxxx(payload)
  //           .then((response) => {
  //               if (response.Status === 0) {
  //                   appContext.setUserDetails(response);
  //                   appContext.xxxxxxxxx({

  //                   });
  //                   saveUserInfo(response.Data);
  //               }
  //           })
  //           .catch((err) => {
  //               console.log(err);
  //           })
  //           .finally(() => {
  //               setShowLoader(false);
  //           });
  //       } catch (err) {
  //           console.log(err);
  //         }
  //   };

  const onEnterFirstName = (value) => {
    if (value.length === 0) {
      setIsFilledFirstName(false);
    } else {
      setIsFilledFirstName(true);
    }
    setFirstName(value);
  };

  const onEnterLastName = (value) => {
    if (value.length === 0) {
      setIsFilledLastName(false);
    } else {
      setIsFilledLastName(true);
    }
    setLasttName(value);
  };

  const onEnterEmail = (value, valid) => {
    if (valid) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
    setEmail(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "25rem",
          maxWidth: "90vw",
          textAlign: "center",
        }}
      >
        <h2 className="page-header">Forgotten Password</h2>
        <p className="page-subheader">
          Enter your details and we will send you a link to reset your password
        </p>
        <Box
          component="form"
          noValidate
          //   onSubmit={handleSubmit}
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextFieldOutlinedInput
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="off"
            placeHolderText={"First Name"}
            onTextChange={onEnterFirstName}
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
            }}
          />
          <TextFieldOutlinedInput
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="off"
            placeHolderText={"Last Name"}
            onTextChange={onEnterLastName}
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
            }}
          />
          <TextFieldOutlinedEmailInput
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            autoComplete="off"
            placeHolderText={"Email adddress"}
            onTextChange={onEnterEmail}
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
            }}
          />

          <Button
            disabled={!isFilledFirstName || !isFilledLastName || !isValidEmail}
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
              bgcolor: "#1e86f0",
              "&:hover": {
                boxShadow: 0,
                bgcolor: "#4da6ff",
              },
              "&:disabled": {
                backgroundColor: "#cde1f4",
                color: "#ffffff",
              },
            }}
          >
            submit
          </Button>
        </Box>
      </Box>
      {showLoader && <Loader />}
    </Box>
  );
}
