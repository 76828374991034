import React, { useContext, useEffect, useState } from "react";
import CommonListView from "../commonListView/CommonListView";
import { useLocation } from "react-router-dom";
import { IconButton, Box } from "@mui/material";
import {
  fetchDashboardAdvancedSearchData,
  fetchDashboardSearchData,
} from "../../services/SearchService";
import { useNavigate } from "react-router-dom";
import SearchResultToolbar from "./SearchResultToolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppContext } from "../../context/AppContext";
import { getSearchDataGridColumns } from "./SearchDataGridColumns";
import Loader from "../loader/Loader";
import GenericError from "../GenericError/GenericError";
import {
  formatDateBasedOnLocale,
  getCurrentDateYYYYMMDDWithTime,
} from "../../utils/DateUtils";
import {
  exportToCsv,
  getLocatedNameByCode,
  getReasonsNameByCode,
  getValidationStatus,
} from "../../utils/CommonUtils";
import { ToastContainer, toast } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import LocationImage from "../../assets/images/search-location.png";
import IpImage from "../../assets/images/search-ip.png";
import TransactionImage from "../../assets/images/search-transactions.png";
import ResultsImage from "../../assets/images/search-results.png";
import { useTheme } from "@emotion/react";

const noDataReasons = [
  {
    title:
      "We couldn't find any transactions that match your criteria. Please make some edits and try again.",
    image: TransactionImage,
  },
  {
    title:
      "We couldn't find any locations that match your criteria. Please make some edits and try again.",
    image: LocationImage,
  },
  {
    title:
      "We couldn't find any IP Addresses that match your criteria. Please make some edits and try again.",
    image: IpImage,
  },
  {
    title:
      "We couldn't find any search results that match your criteria. Please make some edits and try again.",
    image: ResultsImage,
  },
];

const SeacrhListView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { searchParam, advanceSearch, type } = location.state || {};
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState(false);
  const [searchListData, setSearchListData] = useState(null);
  const [requestParam, setRequestParam] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (advanceSearch) {
      getAdvanceSearchResults(searchParam);
    } else {
      getSearchResults(searchParam);
    }
  }, [searchParam]);

  useEffect(() => {
    if (!requestParam) return;
    if (advanceSearch) {
      getAdvanceSearchResults(requestParam);
    } else {
      getSearchResults(requestParam);
    }
  }, [requestParam]);

  const getErrorByType = () => {
    if (searchParam.GeoReferenceId) {
      return noDataReasons[0];
    } else if (searchParam.Latitude && searchParam.Longitude) {
      return noDataReasons[1];
    } else if (searchParam.IpAddress) {
      return noDataReasons[2];
    } else {
      return noDataReasons[3];
    }
  };

  const getSearchResults = (payload) => {
    setShowLoader(true);
    fetchDashboardSearchData(payload)
      .then((response) => {
        if (response.Status === 0) {
          if (
            type &&
            type === "AccountId" &&
            response.Data.Results.length === 1
          ) {
            const data = response.Data.Results[0];
            navigate("/summary/account/" + data.AccountID, {
              state: { type: "account", id: data.AccountID },
            });
          } else {
            setSearchListData(response);
            setShowError(false);
          }
        } else {
          validateAnsShowServerException(toast, 2, response.Status, appContext);
          setSearchListData(null);
          setShowError(true);
        }
      })
      .catch((err) => {
        setShowError(true);
        setSearchListData(null);
        showCatchedExceptionOnToast(toast, 2, err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getAdvanceSearchResults = (payload) => {
    setShowLoader(true);
    fetchDashboardAdvancedSearchData(payload)
      .then((response) => {
        if (response.Status === 0) {
          setSearchListData(response);
          setShowError(false);
        } else {
          validateAnsShowServerException(toast, 2, response.Status, appContext);
          setShowError(true);
          setSearchListData(null);
        }
      })
      .catch((err) => {
        setShowError(true);
        setSearchListData(null);
        showCatchedExceptionOnToast(toast, 2, err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onExportData = () => {
    const strDateTime = getCurrentDateYYYYMMDDWithTime();
    const filename = "search_" + strDateTime + ".csv";
    const data = searchListData?.Data.Results.map((item) => {
      return {
        "Customer (AccountId)": item.AccountID,
        "Device ID": item.DeviceID,
        "Transaaction ID": item.GeoReferenceId,
        "Transaaction Date": formatDateBasedOnLocale(item.Timestamp),
        Status: getValidationStatus(item.Validated),
        "Reason For Failure": getReasonsNameByCode(
          item.ReasonForFailure,
          appContext.failedReasons
        ),
        Location: item.Latitude + ", " + item.Longitude,
        "Located By": getLocatedNameByCode(item.GeoLookUpType),
      };
    });
    exportToCsv(filename, data);
  };
  const onPrintData = () => {};
  const onClickBack = () => {
    navigate(-1);
  };

  const refreshDate = (data) => {
    const request = { ...searchParam, PageNumber: data.page };
    setRequestParam(request);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <IconButton onClick={onClickBack}>
          <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <SearchResultToolbar
          resultCount={
            searchListData && searchListData.Data
              ? searchListData.Data.TotalRecords
              : 0
          }
          exportHandler={onExportData}
          printHandler={onPrintData}
        />
      </Box>

      <Box sx={{ marginTop: "1rem" }}>
        {searchListData &&
          searchListData.Data &&
          searchListData.Data.Results.length > 0 && (
            <CommonListView
              rows={searchListData?.Data.Results}
              columns={getSearchDataGridColumns(appContext.failedReasons, theme)}
              rowCount={searchListData.Data.TotalRecords}
              // pageSize={searchListData.PageSize}
              toolbar={null}
              pageSize={100}
              onRefreshData={(paginationModel) => refreshDate(paginationModel)}
              getRowId={(row) => row.GeoReferenceId}
              checkboxSelection={false}
            />
          )}
        {searchListData && searchListData.Data === null && (
          <GenericError data={getErrorByType()} />
        )}
        {searchListData &&
          searchListData.Data &&
          searchListData.Data.Results.length === 0 && (
            <GenericError data={getErrorByType()} />
          )}
        {!searchListData && showError && (
          <GenericError data={getErrorByType()} />
        )}
      </Box>
      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default SeacrhListView;
