import ReportPageContainer from "../components/reports/ReportPageContainer";
import { Main } from "../components/shared/Main";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";

const Reports = () => {
  const appContext = useContext(AppContext);

  return (
    <Main
      open={appContext.drawerOpen}
      sx={{
        overflowX: "hidden",
        height: '100vh' 
      }}
    >
      <DrawerHeader />
      <ReportPageContainer />
    </Main>
  );
};

export default Reports;
