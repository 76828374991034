import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getColumns,
  getLocatedNameByCode,
  exportToCsv,
  getReasonsNameByCode,
} from "../../utils/CommonUtils";
import { AppContext } from "../../context/AppContext";
import {
  getCurrentDateYYYYMMDDWithTime,
  formatDateBasedOnLocale,
  getCurrentDateYYYYMMDD,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import {
  fetchDeviceTransactionsSummaryReport,
  fetchAccountTransactionsSummaryReport,
} from "../../services/SummaryService";
import Loader from "../loader/Loader";
import {
  SUMMARY_DEV2T,
  DEFAULT_TIMEZONE_OFFSET,
  SUMMARY_ACC2T,
} from "../../constants/Constants";
import TransactionsToolbar from "./TransactionsToolbar";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ToastContainer, toast } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import { getColumnsForTransactionsSummary } from "./SummaryDataGridColumns";
import CommonListView from "../commonListView/CommonListView";
import { REPORT_ACC2T, REPORT_DEV2T } from "../../constants/APIConstants";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TransactionsToolbarMobile from "./TransactionsToolbarMobile";

const TransactionsPageContainer = (props) => {
  const {
    transactions,
    summaryType,
    summaryId,
    tabIndex,
    shiftColumn,
    refreshDataHandler,
  } = props;
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { getUserReportByAPI } = appContext;

  const [showLoader, setShowLoader] = useState(false);
  const [summaryTransactionsData, setSummaryTransactionsData] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [dateFilter, setDateFilter] = useState({
    startDate: getDateBySubtractMonth(3),
    endDate: getCurrentDateYYYYMMDD(),
  });

  // const [dateFilter, setDateFilter] = useState({
  //   startDate: "2024/01/01",
  //   endDate: "2024/09/01",
  // });

  const [selectedPage, setSelectedPage] = useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    if (summaryType === "device") {
      fetchDeviceTransactionsSummaryReportData();
    } else if (summaryType === "account") {
      fetchAccountTransactionsSummaryReportData();
    }
  }, [dateFilter, selectedPage]);

  const onClickBack = () => {
    navigate(-1);
  };

  const fetchDeviceTransactionsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_DEV2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_DEV2T,
        appId: appContext.selectedUserApp.AppId,
        DeviceID: summaryId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };
      console.log(payload)
      setShowLoader(true);
      fetchDeviceTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryTransactionsData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };
  const fetchAccountTransactionsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_ACC2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_ACC2T,
        appId: appContext.selectedUserApp.AppId,
        AccountId: summaryId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };
      setShowLoader(true);
      fetchAccountTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryTransactionsData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onExportData = () => {
    const strDateTime = getCurrentDateYYYYMMDDWithTime();
    const filename = summaryId + "_lookups_" + strDateTime + ".csv";
    if (summaryType === "device") {
      const data = transactions.Data.Results.map((item) => {
        return {
          "Customer (AccountId)": item.AccountId,
          "Transaction Date": formatDateBasedOnLocale(item.Timestamp),
          Status: getReasonsNameByCode(
            item.ReasonForFailure,
            appContext.failedReasons
          ),
          Location: item.Latitude + ", " + item.Longitude,
          "Located By": getLocatedNameByCode(item.GeoLookUpType),
          "Transaction ID": item.GeoReferenceId,
        };
      });
      exportToCsv(filename, data);
    } else {
      const data = transactions.Data.Results.map((item) => {
        return {
          "Device ID": item.DeviceId,
          "Transaction Date": formatDateBasedOnLocale(item.Timestamp),
          Status: getReasonsNameByCode(
            item.ReasonForFailure,
            appContext.failedReasons
          ),
          Location: item.Latitude + ", " + item.Longitude,
          "Located By": getLocatedNameByCode(item.GeoLookUpType),
          "Transaction ID": item.GeoReferenceId,
        };
      });
      exportToCsv(filename, data);
    }
  };

  const onPrintData = () => {
    // window.print();
  };

  const onRefreshData = (data, type) => {
    if (type === 1) {
      setDateFilter({
        ...dateFilter,
        startDate: data.startDate,
        endDate: data.endDate,
      });
    } else if (type === 2) {
      setSelectedPage(data);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <IconButton onClick={onClickBack}>
          <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        {isDesktop ? (
          <TransactionsToolbar
            summaryType={summaryType}
            summaryId={summaryId}
            exportHandler={onExportData}
            printHandler={onPrintData}
            refreshDataHandler={onRefreshData}
          />
        ) : (
          <TransactionsToolbarMobile
            summaryType={summaryType}
            summaryId={summaryId}
            exportHandler={onExportData}
            printHandler={onPrintData}
            refreshDataHandler={onRefreshData}
          />
        )}
      </Box>
      {summaryTransactionsData && (
        <Box
          sx={{ bgcolor: "#ffffff", borderRadius: "5px", marginTop: "1rem" }}
        >
          <CommonListView
            rows={summaryTransactionsData.Data.Results}
            columns={getColumnsForTransactionsSummary(
              summaryType,
              appContext.failedReasons,
              shiftColumn,
              theme
            )}
            rowCount={summaryTransactionsData.Data.TotalRecords}
            pageSize={100}
            onRefreshData={(paginationModel) =>
              onRefreshData(paginationModel, 2)
            }
            getRowId={(row) => row.GeoReferenceId}
            height={tabIndex === 1 ? "70vh" : "50vh"}
            toolbar={null}
            checkboxSelection={false}
          />
        </Box>
      )}
      {showLoader && <Loader />}
    </>
  );
};

export default TransactionsPageContainer;
